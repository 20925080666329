import './SocialMediaResources.css';
import {SocialMediaResource} from "./resource/SocialMediaResource";

import en_payload from '../config/en.json';
import ru_payload from '../config/ru.json';

const payload = () => {
    const domain = window.location.hostname;

    // production
    if (domain === "www.ilyukou.com" || domain === "ilyukou.com") {
        return en_payload;
    }

    if (domain === "ru.ilyukou.com") {
        return ru_payload;
    }

    // development
    if (domain === "localhost") {
        return en_payload;
    }

    if (domain === "127.0.0.1") {
        return ru_payload;
    }

    // default
    return en_payload;
}

export const SocialMediaResources = () => {
    let content = payload();

    return <>
        <div className="row marginTop50px" style={{marginTop: '40vh'}}>
            <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>
            <div className="col-10 col-sm-8 col-md-6 col-xl-4">
                <p className="fs-1 text-center">
                    {content.title}
                </p>
            </div>
            <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>
        </div>

        {/*<div className="row marginTop50px">*/}
        {/*    <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>*/}
        {/*    <div className="col-10 col-sm-8 col-md-6 col-xl-4">*/}
        {/*        <p className="fs-3 text-center">*/}
        {/*            {content.description}*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>*/}
        {/*</div>*/}

        {/*<div className="row marginTop10px">*/}
        {/*    <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>*/}
        {/*    <div className="col-10 col-sm-8 col-md-6 col-xl-4">*/}
        {/*        <p className="fs-3 text-center">*/}
        {/*            {content.subDescription}*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    <div className="col-1 col-sm-2 col-md-3 col-xl-4"/>*/}
        {/*</div>*/}

        {/*<SocialMediaResource data={content.resources}/>*/}
    </>
}
