import {SocialMediaResources} from "./components/resources/SocialMediaResources";
function App() {
  return (
      <div className="container-fluid">
          <SocialMediaResources/>
      </div>
  );
}

export default App;
